import { configureStore } from "@reduxjs/toolkit";
import monthScheduleReducer from "../features/Schedule/Month/MonthScheduleSlice";
import toastReducer from "../features/ToastSlice/toastSlice";
import authReducer from "../features/Auth Slice/authSlice";
import timeReducer from "../features/Time Slice/timeSlice";
import requestSlice from "../features/Request/requestSlice";

export const store = configureStore({
  reducer: {
    monthSchedule: monthScheduleReducer,
    auth: authReducer,
    toast: toastReducer,
    time: timeReducer,
    request: requestSlice,
  },
});
// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
