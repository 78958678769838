import React, { useEffect, useState } from "react";
import PageTitle from "../../Components/layouts/header/page-title";
import FilterSideBar from "../../Components/UI/FilterSideBar";
import HeaderFilter from "./widgets/HeaderFilter";
import SwapListing from "./widgets/SwapListing";
import { getSpecificEmployeeDataForMonth } from "../../services/schedule.service";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store/store";
import { getSwapRequest } from "../../services/request.service";
import {
  requestsSelector,
  setRequests,
} from "../../features/Request/requestSlice";

type MenuItemData = any;

export interface ShiftDayDetails {
  date: string; // ISO date string
  day: string; // Name of the day
  dayNumber: number; // Day of the month
  isDayBalanced: boolean; // Day shift balance status
  isDayOverUtilization: boolean; // Day shift over utilization status
  isDayShift: boolean; // Whether it's a day shift
  isNightBalanced: boolean; // Night shift balance status
  isNightOverUtilization: boolean; // Night shift over utilization status
  isNightShift: boolean; // Whether it's a night shift
  isOnLeave: boolean; // Leave status
  isOnTimeOff: boolean; // Time off status
  leaveType: string; // Type of leave if applicable
}

interface IEmployeeInfo {
  _id: string;
  firstName: string;
  lastName: string;
}

interface IBranchInfo {
  _id: string;
}

export interface ISwapRequestResponse {
  _id: string;
  employee: IEmployeeInfo;
  branch: IBranchInfo;
  day: number;
  month: number;
  year: number;
  shift: "day" | "night"; // assuming these are the only possible values
  active: boolean;
  createdAt: string; // or Date if you're parsing it
}

const Swap = () => {
  const { date } = useSelector((state: RootState) => state.time);
  const [shifts, setShifts] = useState<ShiftDayDetails[]>([]);
  const dispatch = useDispatch();
  const requests = useSelector(requestsSelector);

  const [sidebarData, setSidebarData] = useState<MenuItemData[]>([
    {
      id: 1,
      label: "Status",
      isOpen: true,
      subItems: [
        { id: 1, label: "All", isSelected: false },
        { id: 2, label: "Requested", isSelected: false },
        { id: 3, label: "Approved", isSelected: false },
        { id: 4, label: "Rejected", isSelected: false },
      ],
    },
  ]);

  useEffect(() => {
    if (date) {
      fetchMonthlySchedule();
      fetchSwapRequests();
    }
  }, [date]);

  const fetchSwapRequests = async () => {
    const res: any = await getSwapRequest();
    if (res?.res) {
      dispatch(setRequests(res.res.data as ISwapRequestResponse[]));
    }
  };

  const fetchMonthlySchedule = async () => {
    const res: any = await getSpecificEmployeeDataForMonth(
      date?.currentMonth,
      date?.year
    );
    if (res?.res?.employeeInfo) {
      setShifts(res.res.employeeInfo.shifts);
    }
    // console.log("Employee Info", res);
  };

  const handleSidebarDataUpdate = (data: MenuItemData[]) => {
    setSidebarData(data);
  };

  return (
    <div className="bg-[#f8f8f8]">
      <PageTitle
        title="Swap"
        subtitle="List of all your requested duty swaps"
      />
      <div className="flex flex-wrap">
        {/* <div className="w-1/4">
          <div className="border-r border-beerus bg-white shadow-[0_8px_24px_-6px_rgba(0,0,0,0.08)]">
            <div className="bg-white pt-[30px] p-[30px]">
              <h3 className="text-moon-20 font-semibold">Filters</h3>
            </div>
            <div className="bg-gohan flex flex-col gap-2 rounded-moon-s-lg">
              <div className="px-[30px] h-[calc(100vh-284px)]">
                <FilterSideBar
                  onDataUpdate={handleSidebarDataUpdate}
                  sidebarData={sidebarData}
                />
              </div>
            </div>
          </div>
        </div> */}
        <div className="w-full">
          <div className="p-[20px_30px]">
            <HeaderFilter shifts={shifts} />
          </div>
          <div className="swaplisting p-[0_20px_30px] h-[calc(100vh-280px)]">
            <SwapListing requests={requests} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Swap;
