import React, { useState } from "react";
import { flexRender } from "@tanstack/react-table";
// import { ITableDataResponse } from "../../types/interfaces/ITableData.interface";
import { IconButton } from "@heathmont/moon-core-tw";
import {
  ArrowsUp,
  ControlsChevronLeftSmall,
  ControlsChevronRightSmall,
} from "@heathmont/moon-icons";
import SimpleBar from "simplebar-react";

interface IRequestOff {
  leaves: IRequest[];
}
interface IRequest {
  monthName: string;
  leaves: ILeaves[];
}
interface ILeaves {
  date: string;
  _id: string;
  month: number;
}
interface Props {
  table: any;
  tableData: IRequestOff;
  handleRowClick: (data: any) => any; // Add this prop
}

// const handleRowClick = (row: any) => {
//   // You can access the row's data here
// };

const Table: React.FC<Props> = ({ table, tableData, handleRowClick }) => {
  const [active, setActive] = useState(0);
  if (tableData?.leaves?.length === 0) {
    return (
      <div className="sectionsvg flex items-center justify-center text-center">
        {/* You can render a loading indicator or an error message here */}
        {/* Loading or No Data Available */}
        <img
          src={"images/no-schedule-to-approve.svg"}
          alt="No schedule to approve"
        />
      </div>
    );
  }

  return (
    <div>
      <SimpleBar className="h-[calc(100vh-270px)] overflow-y-auto">
        <table className="coustomstyl w-full border-spacing-y-1 border-separate">
          <thead>
            {table
              ?.getHeaderGroups()
              ?.map((headerGroup: any, index: number) => (
                <tr key={index}>
                  {headerGroup.headers.map((header: any) => (
                    <th
                      key={header.id}
                      colSpan={header.colSpan}
                      className="text-left px-4 "
                    >
                      {header.isPlaceholder ? null : (
                        <div
                          {...{
                            className: header.column.getCanSort()
                              ? "cursor-pointer select-none flex justify-between"
                              : "",
                            onClick: header.column.getToggleSortingHandler(),
                          }}
                        >
                          {flexRender(
                            header.column.columnDef.header,
                            header.getContext(),
                          )}
                        </div>
                      )}
                    </th>
                  ))}
                </tr>
              ))}
          </thead>
          <tbody className="">
            {table?.getRowModel()?.rows?.map((row: any, index: number) => (
              <tr
                key={index}
                onClick={() => {
                  handleRowClick(row);
                  setActive(index);
                }}
                className={`bg-white p-2 cursor-pointer  ${
                  active === index ? "highlighted-table-row" : ""
                }`}
              >
                {row?.getVisibleCells()?.map((cell: any) => (
                  <td
                    key={cell?.id}
                    className={`text-left py-2 px-4 text-moon-14  `}
                  >
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
          <tfoot>
            {table
              ?.getFooterGroups()
              ?.map((footerGroup: any, index: number) => (
                <tr key={index}>
                  {footerGroup?.headers?.map((header: any, index: number) => (
                    <th key={index}>
                      {header.isPlaceholder
                        ? null
                        : flexRender(
                            header.column.columnDef.footer,
                            header.getContext(),
                          )}
                    </th>
                  ))}
                </tr>
              ))}
          </tfoot>
        </table>
        {/*  */}
      </SimpleBar>
      {/* <div className="flex items-center p-4">
        <div className="w-1/3">
          <div className="flex items-center justify-center text-moon-12">
            <div>Showing </div> &nbsp;
            {tableData?.currentPage * Number(tableData?.limit) -
              Number(tableData?.limit) +
              1}{" "}
            -{" "}
            {tableData?.currentPage * Number(tableData?.limit) -
              Number(tableData?.limit) +
              Number(tableData?.records?.length)}{" "}
            of {Number(tableData?.totalRecords)}
          </div>
        </div>
        <div className="w-2/3">
          <div className="flex items-center justify-between">
            <IconButton
              icon={<ControlsChevronLeftSmall className="rtl:rotate-180" />}
              variant="secondary"
              size="sm"
              onClick={() => {
                handlePageChange(tableData?.currentPage - 1);
              }}
              disabled={!tableData?.hasPrevPage}
              aria-label="Previous"
            />
            <div className="flex items-center gap-2">
              {generatePageNumbers(tableData).map((pageNumber, index) => (
                <React.Fragment key={index}>
                  {pageNumber === "..." ? (
                    <span className="mx-1">{pageNumber}</span>
                  ) : (
                    <button
                      className={`flex items-center justify-center cursor-pointer w-8 h-8 rounded-moon-s-sm focus:outline-none transition-colors font-medium ${
                        pageNumber === tableData?.currentPage
                          ? "bg-primary text-white"
                          : ""
                      }`}
                      onClick={() =>
                        typeof pageNumber === "number" &&
                        handlePageChange(pageNumber)
                      }
                    >
                      {pageNumber}
                    </button>
                  )}
                </React.Fragment>
              ))}
            </div>

            <IconButton
              icon={<ControlsChevronRightSmall className="rtl:rotate-180" />}
              variant="secondary"
              size="sm"
              onClick={() => {
                handlePageChange(tableData?.currentPage + 1);
              }}
              disabled={!tableData?.hasNextPage}
              aria-label="Previous"
            />
          </div>
        </div>
      </div> */}
    </div>
  );
};

export default Table;
