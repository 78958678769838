import React, { useEffect, useState } from "react";
import Time from "../../../Widgets/time/Time";
import Greeting from "../../../Widgets/greeting/Greeting";
import NurseSchedule from "../../../Widgets/schedule/NurseSchedule";
import NeedEmployee from "../../../NeedEmployee";
import { useSelector } from "react-redux";
import { RootState } from "../../../../store/store";
import { IUserData } from "../../../../types/interfaces/IUserData.interface";
import AccountStats from "../../../../pages/account/widgets/AccountStats";
import moment from "moment";
import { generateWeeks } from "../../../../utils/weeks";
import { getShiftsRemainingLeavesWeeklyHours } from "../../../../services/employee.service";
import { currentYear } from "../../../../features/Time Slice/timeSlice";

const NurseHeader = () => {
  const user = useSelector((state: RootState) => state.auth.User);
  const [month, setMonth] = useState({
    name: moment().format("MMMM"),
    monthName: moment().format("MMMM"),
    label: moment().format("M"),
  });
  const [leavesData, setLeavesData] = useState<any>();

  function getWeeksInMonth() {
    const firstDay = new Date(moment().year(), +month.label - 1, 1);
    const lastDay = new Date(moment().year(), +month.label, 0);

    const daysInMonth = lastDay.getDate();
    return Math.ceil(daysInMonth / 7);
  }

  const numberOfWeeks = getWeeksInMonth();

  const weeks = generateWeeks(numberOfWeeks);
  const months = Array.from({ length: 12 }, (_, index) => ({
    name: `${moment().month(index).format("MMMM")}`,
    monthName: moment().month(index).format("MMMM"),
    label: index + 1,
  }));

  const [week, setWeek] = useState(weeks[0]);

  const fetchShiftsRemainingLeavesWeeklyHours = async () => {
    const res = await getShiftsRemainingLeavesWeeklyHours(
      +month?.label,
      currentYear,
      week.label
    );
    if (res?.res) {
      setLeavesData(res?.res);
    }
  };

  useEffect(() => {
    fetchShiftsRemainingLeavesWeeklyHours();
  }, [week, month]);
  return (
    <div className="pb-5 pt-[80px] px-7 bg-gradient-to-tll">
      <div className="flex flex-wrap items-center -mx-[4px]">
        <div className="w-1/4 px-[4px]">
          <div>
            <Greeting designation={user?.designation as string} />
            <h2 className="text-white 2xl:text-moon-32 text-moon-24 font-semibold mb-2">
              {user?.employeeName}
            </h2>
            <Time />
          </div>
        </div>
        {/* <div className="w-1/4 px-[4px]"></div> */}
        <div className="w-3/4 px-[4px]">
          <div className="grid grid-cols-4 gap-1">
            <div>
              <NurseSchedule />
            </div>
            <div>
              <AccountStats
                title={"Total Schedules"}
                value={month}
                statsNumber={leavesData?.totalSchedules}
                outOff={""}
                options={months}
                bgColor="bg-white"
                setOptions={setMonth}
              />
            </div>
            <div>
              <AccountStats
                title={"Remaining Leaves"}
                value={""}
                statsNumber={leavesData?.remainingLeaves}
                outOff={leavesData?.totalLeaves}
                options={[]}
                bgColor="bg-white"
                setOptions={setWeek}
              />
            </div>
            <div>
              <AccountStats
                title={"Hours Worked"}
                value={weeks[0]}
                statsNumber={leavesData?.hoursWorked}
                outOff={leavesData?.hoursAligned}
                options={weeks}
                bgColor="bg-white"
                setOptions={setWeek}
              />
            </div>
          </div>
          {/* <NeedEmployee /> */}
        </div>
      </div>
    </div>
  );
};

export default NurseHeader;
