import React from "react";
import CardShiftList from "./CardShiftList";
import { ISwapRequestResponse } from "..";
const cardsData = [
  {
    img: "images/Avatar.png",
    name: " Floyd Miles",
    date: "27 October 2023",
    time: "Day",
    status: "Approved",
  },
  {
    img: "images/Avatar.png",
    name: " Floyd Miles",
    date: "27 October 2023",
    time: "Day",
    status: "Requested",
  },
  {
    img: "images/Avatar.png",
    name: " Floyd Miles",
    date: "27 October 2023",
    time: "Day",
    status: "Rejected",
  },
  {
    img: "images/Avatar.png",
    name: " Floyd Miles",
    date: "27 October 2023",
    time: "Night",
    status: "Requested",
  },
  {
    img: "images/Avatar.png",
    name: " Floyd Miles",
    date: "27 October 2023",
    time: "Night",
    status: "Rejected",
  },
  {
    img: "images/Avatar.png",
    name: " Floyd Miles",
    date: "27 October 2023",
    time: "Night",
    status: "Requested",
  },
  {
    img: "images/Avatar.png",
    name: " Floyd Miles",
    date: "27 October 2023",
    time: "Day",
    status: "Rejected",
  },
  {
    img: "images/Avatar.png",
    name: " Floyd Miles",
    date: "27 October 2023",
    time: "Day",
    status: "Requested",
  },
];
const SwapListing = ({ requests }: { requests: ISwapRequestResponse[] }) => {
  return (
    <div>
      <div className="rounded-[8px] p-[4px] bg-goku  overflow-hidden">
        <div className="flex flex-wrap items-center mb-[4px]">
          <div className="2xl:flex-[0_0_246px] flex-[0_0_200px] p-[17px_16px]">
            <h3 className="font-semibold text-blackcst text-moon-18">
              Requested On
            </h3>
          </div>
          <div className="2xl:flex-[0_0_246px] flex-[0_0_200px] p-[17px_16px]">
            <h3 className="font-semibold text-blackcst text-moon-18">
              Shift Date
            </h3>
          </div>
          <div className="2xl:flex-[0_0_246px] flex-[0_0_200px] p-[17px_16px]">
            <h3 className="font-semibold text-blackcst text-moon-18">Shift</h3>
          </div>
        </div>
        <div>
          {requests.map((card, index) => (
            <CardShiftList key={index} {...card} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default SwapListing;
