import React, { useEffect, useState } from "react";
import { Dropdown, MenuItem } from "@heathmont/moon-core-tw";
import { ControlsChevronDown } from "@heathmont/moon-icons-tw";

const AccountStats = ({
  value,
  statsNumber,
  title,
  options,
  outOff,
  textColor,
  setOptions,
}: any) => {
  const [option, setOption] = useState(value);

  return (
    <div
      className={`bg-[rgba(39,45,54,0.15)] h-full ${textColor} p-4 rounded-md flex`}
    >
      <div className="flex-1">
        <h3 className="text-moon-14 text-white">{title}</h3>
        <div className="font-semibold text-[32px] leading-[40px] text-white">
          {statsNumber}{" "}
          {outOff !== "" ? (
            <span className="font-normal text-moon-16 relative before:content-['/'] before:inline-block before:mr-[5px]">
              {outOff}
            </span>
          ) : (
            ""
          )}
        </div>
      </div>
      <div>
        {value ? (
          <Dropdown
            value={option}
            onChange={(option) => {
              setOption(option);
              setOptions(option);
            }}
            position="bottom-end"
          >
            <Dropdown.Trigger className="inline-flex items-center cursor-pointer z-50">
              <span className="text-moon-14 cursor-pointer text-white">
                {option?.name}
              </span>
              <ControlsChevronDown className="ml-2 text-white" />
            </Dropdown.Trigger>
            <Dropdown.Options className="min-w-[100px] z-50">
              {options.map((option: any, index: any) => (
                <Dropdown.Option value={option} key={index}>
                  {({ selected, active }) => (
                    <MenuItem
                      isActive={active}
                      isSelected={selected}
                      className="text-moon-12"
                      value={option.value}
                    >
                      {option.name}
                    </MenuItem>
                  )}
                </Dropdown.Option>
              ))}
            </Dropdown.Options>
          </Dropdown>
        ) : (
          ""
        )}{" "}
      </div>
    </div>
  );
};

export default AccountStats;
