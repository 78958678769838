import React, { useEffect, useState } from "react";
import {
  ControlsChevronRight,
  ControlsChevronLeft,
} from "@heathmont/moon-icons-tw";
import { IconButton } from "@heathmont/moon-core-tw";
import { ShiftDayDetails } from "..";
import { useSelector } from "react-redux";
import { RootState } from "../../../store/store";

interface SelectableDate {
  month: number;
  days: number[];
}

interface SelectedDate {
  year: number;
  month: number;
  day: number;
  shift: "day" | "night";
}

interface Shifts {
  shifts: ShiftDayDetails[];
  shift: "day" | "night";
  selectedDate: SelectedDate | null;
  setSelectedDate: (date: SelectedDate | null) => void;
}

const weekdays = ["M", "T", "W", "T", "F", "S", "S"];

const CalendarSwap: React.FC<Shifts> = ({
  shifts,
  shift,
  selectedDate,
  setSelectedDate,
}) => {
  const { date } = useSelector((state: RootState) => state.time);
  const [currentDate, setCurrentDate] = useState(new Date());
  const [selectable, setSelectable] = useState<SelectableDate[]>([]);

  useEffect(() => {
    setSelectedDate(null);

    const selectableDays = shifts
      .filter((shiftDay) => shiftDay.isNightShift || shiftDay.isDayShift)
      .map((shiftDay) => shiftDay.dayNumber);

    setSelectable([
      {
        month: date.currentMonth - 1,
        days: selectableDays,
      },
    ]);
  }, [date, shifts]);

  const handlePrevMonth = () => {
    setCurrentDate(
      new Date(currentDate.getFullYear(), currentDate.getMonth() - 1)
    );
  };

  const handleNextMonth = () => {
    setCurrentDate(
      new Date(currentDate.getFullYear(), currentDate.getMonth() + 1)
    );
  };

  const isDateSelectable = (day: number, month: number) => {
    const date = new Date();
    const monthConfig = selectable.find((config) => config.month === month);
    return monthConfig?.days.includes(day) && date.getDate() <= day;
  };

  const handleDayClick = (day: number) => {
    const currentMonth = currentDate.getMonth();

    if (isDateSelectable(day, currentMonth)) {
      if (selectedDate?.month === currentMonth && selectedDate?.day === day) {
        setSelectedDate(null);
      } else {
        setSelectedDate({
          month: currentMonth,
          day,
          year: currentDate.getFullYear(),
          shift: shifts.find(
            (shft) =>
              new Date(shft.date).getDate() === day &&
              new Date(shft.date).getMonth() === currentMonth
          )?.isDayShift
            ? "day"
            : "night",
        });
      }
    }
  };

  const daysInMonth = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth() + 1,
    0
  ).getDate();

  const startDay =
    (new Date(currentDate.getFullYear(), currentDate.getMonth(), 1).getDay() +
      6) %
    7;

  const monthName = currentDate.toLocaleString("default", { month: "long" });
  const year = currentDate.getFullYear();

  return (
    <div className="flex items-center justify-center">
      <div className="w-full">
        <div className="bg-white rounded-[10px] shadow-shodowCst p-4">
          <div className="flex items-center justify-between">
            <IconButton
              variant="ghost"
              onClick={handlePrevMonth}
              className="font-semibold w-[40px] border-goku border shadow-none hover:shadow-none text-moon-18"
            >
              <ControlsChevronLeft />
            </IconButton>
            <h3 className="text-moon-12 text-bulma font-semibold text-center py-[5.62px]">
              {`${monthName} ${year}`}
            </h3>
            <IconButton
              variant="ghost"
              onClick={handleNextMonth}
              className="font-semibold w-[40px] border-goku border shadow-none hover:shadow-none text-moon-18"
            >
              <ControlsChevronRight />
            </IconButton>
          </div>

          <div className="grid grid-cols-7 gap-1">
            {weekdays.map((weekday, i) => (
              <p
                key={weekday + i}
                className="text-center text-moon-14 font-semibold text-gray-500 py-2 text-trunks"
              >
                {weekday}
              </p>
            ))}
            {Array.from({ length: startDay }, (_, index) => (
              <p
                key={`empty-${index}`}
                className="text-base text-center px-2 py-3"
              ></p>
            ))}
            {Array.from({ length: daysInMonth }, (_, index) => {
              const day = index + 1;
              const isSelectable = isDateSelectable(
                day,
                currentDate.getMonth()
              );
              const isDayShift = shifts.find(
                (shift) => new Date(shift.date).getDate() === day
              );
              const isSelected =
                selectedDate?.month === currentDate.getMonth() &&
                selectedDate?.day === day;

              return (
                <p
                  key={`day-${index}`}
                  onClick={() => handleDayClick(day)}
                  className={`text-base font-medium text-center px-2 py-2 w-full h-full rounded-[11.25px] border transition-colors duration-200
                    ${
                      isSelectable
                        ? "cursor-pointer"
                        : "cursor-not-allowed opacity-50"
                    } 
                    ${
                      isSelected
                        ? "bg-hit text-white hover:bg-hit hover:text-white"
                        : isSelectable
                        ? `border-hit text-hit hover:bg-hit/20 hover:text-hit hover:border-hit ${
                            isDayShift?.isDayShift
                              ? "bg-[#ffca4f] text-white"
                              : isDayShift?.isNightShift
                              ? "bg-[#272d36] text-white"
                              : ""
                          }`
                        : " text-gray-600"
                    }`}
                >
                  {day}
                </p>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CalendarSwap;
