import React, { useEffect, useState } from "react";
import AccountHeader from "./widgets/AccountHeader";
import ProfileSidebar from "./widgets/ProfileSidebar";
import { Button, Chip, Input, Label, Tabs } from "@heathmont/moon-core-tw";
import Preferences from "./widgets/Preferences";
import Calendar from "./widgets/Calendar";
import { Icon } from "@iconify/react";
import { OtherMoon } from "@heathmont/moon-icons-tw";
import { jwtDecode } from "jwt-decode";
import { setAuthUser } from "../../features/Auth Slice/authSlice";
import { storeTokens } from "../../services/auth.service";
import { store } from "../../store/store";
import { changePassword, editEmployeeProfileData } from "../../services/employee.service";
import {
  setSnackBar,
  setToastMessage,
} from "../../features/ToastSlice/toastSlice";
import { deepEqual } from "../../utils/checkObjects";


interface IChangePassword {
  newPassword: string;
  currentPassword: string;
}

interface IProfileEdit {
  firstName: string;
  lastName: string;
  contactNo: string;
  address: string;
}

const Account = () => {
  const profile = JSON.parse(sessionStorage.getItem("user")!);
  const [selectedTab,setSelectedTab] = useState<number>(0)
  const [profileData, setProfileData] = useState<IProfileEdit>({
    firstName: profile?.firstName,
    lastName: profile?.lastName,
    contactNo: profile?.contactNo,
    address: profile?.address,
  });
  const [profileDataCopy, setProfileDataCopy] = useState<IProfileEdit>({
    firstName: profile?.firstName,
    lastName: profile?.lastName,
    contactNo: profile?.contactNo,
    address: profile?.address,
  });
  const [validationErrors, setValidationErrors] = useState({
    firstName: "",
    lastName: "",
    email: "",
    contactNo: "",
    address: "",
  });

  const [password, setPassword] = useState<IChangePassword>({
    currentPassword: '',
    newPassword: '',
  });

  const [confirmPassword, setConfirmPassword] = useState("");
  const [touched, setTouched] = useState<{
    [K in keyof IChangePassword]?: boolean;
  }>({});
  const [errors, setErrors] = useState<{
    [K in keyof IChangePassword]?: string;
  }>({});


  const [passwordError, setPasswordError] = useState("");
  const passwordRegex = /.{8,32}$/;


  const inputChangeHandler = (event: any) => {
    setProfileData((prevData) => {
      return { ...prevData, [event.target.name]: event.target.value };
    });

    validateInput(event.target.name, event.target.value);
  };

  const validateInput = (name: string, value: string) => {
    // Implement your validation logic here
    // For simplicity, let's assume all fields are required

    if (value.trim() === "") {
      setValidationErrors((prevErrors) => ({
        ...prevErrors,
        [name]: `${name} is required.`,
      }));
    } else {
      setValidationErrors((prevErrors) => ({
        ...prevErrors,
        [name]: "",
      }));
    }

    // Phone number (contactNo) validation
    if (name === "contactNo") {
      const phonePattern = /^\+?[0-9]+$/; // Assumes international phone format
      if (!phonePattern.test(value)) {
        setValidationErrors((prevErrors) => ({
          ...prevErrors,
          contactNo: "Invalid phone number.",
        }));
      } else {
        setValidationErrors((prevErrors) => ({
          ...prevErrors,
          contactNo: "",
        }));
      }
    }

    // Validate first name and last name length
    if (name === "firstName" || name === "lastName") {
      if (value.length > 20) {
        const error = name == "firstName" ? "First Name" : "Last Name";
        setValidationErrors((prevErrors) => ({
          ...prevErrors,
          [name]: `${error} should not be more than 20 characters.`,
        }));
      } else {
        setValidationErrors((prevErrors) => ({
          ...prevErrors,
          [name]: "",
        }));
      }
    }
  };

  const handleSubmit = async () => {
    // Check if there are any validation errors
    const hasErrors = Object.values(validationErrors).some(
      (error) => error !== "",
    );

    if (deepEqual(profileData, profileDataCopy)) {
      store.dispatch(setSnackBar("error"));
      store.dispatch(setToastMessage("There is not any change"));
      return;
    }

    console.log(
      "Profile Data Copy",
      profileDataCopy,
      "profileData",
      profileData,
      "Deep",
      deepEqual(profileData, profileDataCopy),
    );

    if (deepEqual(profileData, profileDataCopy)) {
      store.dispatch(setSnackBar("error"));
      store.dispatch(setToastMessage("There is not any change"));
      return;
    }

    console.log(
      "Profile Data Copy",
      profileDataCopy,
      "profileData",
      profileData,
      "Deep",
      deepEqual(profileData, profileDataCopy),
    );

    if (hasErrors) {
      store.dispatch(setSnackBar("error"));
      store.dispatch(setToastMessage("Please Enter Valid Data"));
    } else {
      const params = {
        firstName: profileData?.firstName,
        lastName: profileData?.lastName,
        contactNo: profileData?.contactNo,
        address: profileData?.address,
      };
      const res: any = await editEmployeeProfileData(params);
      if (res) {
        const tokenData = jwtDecode(res.res.accessToken);
        sessionStorage.setItem("user", JSON.stringify(tokenData));
        store.dispatch(setAuthUser(tokenData));
        storeTokens(res.res);
        store.dispatch(setSnackBar("success"));
        store.dispatch(setToastMessage("Profile Updated Successfully"));
        const updatedProfile = JSON.parse(sessionStorage.getItem("user")!);
        setProfileDataCopy({
          firstName: updatedProfile?.firstName,
          lastName: updatedProfile?.lastName,
          contactNo: updatedProfile?.contactNo,
          address: updatedProfile?.address,
        });
      }
    }
  };


  
  const handleChangePassword = async () => {
    const res = await changePassword(password)
    if(res?.res){
      store.dispatch(setSnackBar("success"));
      store.dispatch(setToastMessage("Password Changed Successfully"));
      setPassword({
        currentPassword: "",
        newPassword: "",
      })
      setConfirmPassword("")
    }
  }

  const handleInputChange = (event: any) => {
    const { name, value } = event.target;
    setPassword((prevPassword) => ({ ...prevPassword, [name]: value }));
  };

  const handleBlur = (field: keyof IChangePassword) => {
    setTouched((prev) => ({ ...prev, [field]: true }));
    validateField(field);
  };

  const handleFocus = (field: keyof IChangePassword) => {
    if(field==="newPassword"){
    setPasswordError("") 
    }
    setErrors((prev) => ({ ...prev, [field]: undefined }));
  };

  const validateField = (field: keyof IChangePassword) => {
    let error: any = null;
    if (field === "newPassword") {
      if (!passwordRegex.test(password.newPassword)) {
        setPasswordError("Password must be 8-32 characters long");
        setErrors((prev) => ({ ...prev, [field]: "Password must be 8-32 characters long" }));

        return;
      } else {
        setPasswordError("");
        return;
      }
    }

    if (!password[field]) {
      error = "This field is required";
    }
    setErrors((prev) => ({ ...prev, [field]: error }));
  };


  const isValid=()=>{
    return password.currentPassword !== '' && password.newPassword !== '' && password.newPassword === confirmPassword && passwordRegex.test(password.newPassword)
  }

  useEffect(()=>{
    if(selectedTab===1){
      setPasswordError("")
      setPassword(  {
        currentPassword: "",
        newPassword: "",
      })
      setConfirmPassword("")
    }
    setErrors({})
  },[selectedTab])

  return (
    <>
      <div className="bg-[#F8F8F8]">
        <AccountHeader />
        <div className="flex flex-wrap">
          <div className="w-1/4">
            <ProfileSidebar />
          </div>
          <div className="w-3/4">
            <div className="p-[43px_28px] ">
              <div>
              <Tabs onChange={(index:number)=>setSelectedTab(index)}>
              <Tabs.List className="gap-[32px]">
                    <Tabs.Tab
                      className={({ selected }) =>
                        `hover:text-hit after:bg-hit px-0 ${
                          selected ? "text-hit" : " px-0"
                        }`
                      }
                    >
                      Basic Info
                    </Tabs.Tab>
                    <Tabs.Tab
                    
                      className={({ selected }) =>
                        `hover:text-hit after:bg-hit px-0 ${
                          selected ? "text-hit" : "px-0"
                        }`
                      }
                    >
                      Change Password
                    </Tabs.Tab>
                  </Tabs.List>

                  <Tabs.Panels className="mt-[16px]">
                    <Tabs.Panel>
                      <div className="max-h-[calc(100vh-350px)] overflow-auto">
                        <div className=" bg-white rounded-[16px]">
                          <div className="p-6">
                            <div className="flex flex-wrap -mx-[12px]">
                              {/* <div className="w-full px-[12px] mb-[24px]">
                              <Label htmlFor="test-3">Full Name</Label>
                              <Input
                                type="text"
                                size="lg"
                                placeholder="Enter first name"
                                name="firstName"
                                value={profileData?.firstName}
                                onChange={inputChangeHandler}
                              />
                              {validationErrors.firstName && (
                                <p className="text-red-500">
                                  {validationErrors.firstName}
                                </p>
                              )}
                              <Label htmlFor="test-3">Full Name</Label>
                              <Input
                                type="text"
                                size="lg"
                                placeholder="Enter Last name"
                                name="lastName"
                                value={profileData?.lastName}
                                onChange={inputChangeHandler}
                              />
                              {validationErrors.lastName && (
                                <p className="text-red-500">
                                  {validationErrors.lastName}
                                </p>
                              )}
                            </div> */}
                              <div className="w-1/2 px-[12px] mb-[24px]">
                                <div className="w-full">
                                  <Label>First Name</Label>
                                  <Input
                                    size="lg"
                                    type="tel"
                                    placeholder="e.g. +372 123 4567"
                                    name="firstName"
                                    value={profileData?.firstName}
                                    onChange={inputChangeHandler}
                                  />
                                  {validationErrors.firstName && (
                                    <p className="text-red-500">
                                      {validationErrors.firstName}
                                    </p>
                                  )}
                                </div>
                              </div>
                              <div className="w-1/2 px-[12px] mb-[24px]">
                                <div className="w-full">
                                  <Label>Last Name</Label>
                                  <Input
                                    size="lg"
                                    type="tel"
                                    placeholder="e.g. +372 123 4567"
                                    name="lastName"
                                    value={profileData?.lastName}
                                    onChange={inputChangeHandler}
                                  />
                                  {validationErrors.lastName && (
                                    <p className="text-red-500">
                                      {validationErrors.lastName}
                                    </p>
                                  )}
                                </div>
                              </div>
                              <div className="w-1/2 px-[12px] mb-[24px]">
                                <Label htmlFor="test-3">Email</Label>
                                <Input
                                  type="text"
                                  size="lg"
                                  placeholder="Enter Your Email"
                                  value={profile?.email}
                                  name="email"
                                  disabled={true}
                                />
                                {validationErrors.email && (
                                  <p className="text-red-500">
                                    {validationErrors.email}
                                  </p>
                                )}
                              </div>
                              <div className="w-1/2 px-[12px] mb-[24px]">
                                <div className="w-full">
                                  <Label>Contact Number</Label>
                                  <Input
                                    size="lg"
                                    type="tel"
                                    placeholder="e.g. +372 123 4567"
                                    name="contactNo"
                                    value={profileData?.contactNo}
                                    onChange={inputChangeHandler}
                                  />
                                  {validationErrors.contactNo && (
                                    <p className="text-red-500">
                                      {validationErrors.contactNo}
                                    </p>
                                  )}
                                </div>
                              </div>
                              <div className="w-full px-[12px] mb-[10px]">
                                <Label htmlFor="test-3">Address</Label>
                                <Input
                                  type="text"
                                  size="lg"
                                  placeholder="Home address"
                                  name="address"
                                  value={profileData?.address}
                                  onChange={inputChangeHandler}
                                />
                                {validationErrors.address && (
                                  <p className="text-red-500">
                                    {validationErrors.address}
                                  </p>
                                )}
                              </div>
                              {/* <div className="w-full px-[12px] mb-[24px]">
                              <Input
                                type="text"
                                size="lg"
                                placeholder="Address line 2"
                              />
                            </div> */}
                            </div>
                          </div>
                          {/* <div className="p-6 border-t border-beerus rounded-[0_0_16px_16px] flex items-center justify-end gap-[8px]">
                          <Button
                            variant="secondary"
                            onClick={() => setProfileData(profile)}
                          >
                            Discard
                          </Button>
                          <Button
                            variant="tertiary"
                            onClick={() => console.log("O PR", profileData)}
                          >
                            Submit
                          </Button>
                        </div> */}
                          <div className="p-6 border-t border-beerus rounded-[0_0_16px_16px] flex items-center justify-end gap-[8px]">
                            <Button
                              variant="secondary"
                              onClick={() => setProfileData(profile)}
                            >
                              Discard
                            </Button>
                            <Button variant="tertiary" onClick={handleSubmit}>
                              Submit
                            </Button>
                          </div>
                        </div>
                      </div>
                    </Tabs.Panel>
                    <Tabs.Panel>
                    <div className=" bg-white rounded-[16px]">
                        <div className="p-6">
                          <div className="flex flex-wrap -mx-[12px]">
                              <div className="w-full px-[12px] mb-[10px]">
                                <Label htmlFor="test-3">Current Password</Label>
                                <Input
                                  type="password"
                                  size="lg"
                                  placeholder="Current Password"
                                  name="currentPassword"
                                  onFocus={()=>handleFocus("currentPassword")}
                                  onBlur={()=>handleBlur("currentPassword")}
                                  value={password.currentPassword}
                                  onChange={handleInputChange}
                                  className={` px-3 py-2 ${
                                    touched.currentPassword && errors.currentPassword
                                    ? "border border-red-500"
                                    : "border-gray-300"
                                  } `}
                                />
                              </div>

                              <div className="w-full px-[12px] mb-[10px]">
                                <Label htmlFor="test-3">New Password</Label>
                                <Input
                                  type="password"
                                  size="lg"
                                  placeholder="New Password"
                                  name="newPassword"
                                  value={password.newPassword}
                                  className={` px-3 py-2  ${
                                    touched.newPassword && errors.newPassword
                                    ? "border border-red-500"
                                    : "border-gray-300"
                                  } `}
                                  onChange={handleInputChange}
                                  onFocus={()=>handleFocus("newPassword")}
                                  onBlur={()=>handleBlur("newPassword")}
                                />
                                {passwordError && (
                                  <p className="text-dodoria text-left text-xs">
                                    {passwordError}
                                  </p>
                                )}
                              </div>

                              <div className="w-full px-[12px] mb-[10px]">
                                <Label htmlFor="test-3">Confirm Password</Label>
                                <Input
                                  type="password"
                                  size="lg"
                                  placeholder="Confirm Password"
                                  name="confirmPassword"
                                  value={confirmPassword}
                                  onChange={(e)=>setConfirmPassword(e.target.value)}
                                  className={ `px-3 py-2 `}
                                />
                                {confirmPassword.length !== 0 &&
                                  password.newPassword !==
                                    confirmPassword && (
                                    <p className="text-dodoria text-left text-xs">
                                      Password doesn't match
                                    </p>
                                  )}
                              </div>
                            </div>
                          </div>
                          <div className="p-6 border-t border-beerus rounded-[0_0_16px_16px] flex items-center justify-end gap-[8px]">
                           
                            <Button variant="tertiary" onClick={handleChangePassword} disabled={isValid()?false:true}>
                              Save
                            </Button>
                          </div>
                      </div>
                    </Tabs.Panel>
                  </Tabs.Panels>
                </Tabs>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Account;
