import { Button } from "@heathmont/moon-core-tw";
import React, { useEffect, useState } from "react";
import { ControlsPlus } from "@heathmont/moon-icons-tw";
import TimeOffDrawer from "./TimeOffDrawer";
import { RootState, store } from "../../../store/store";
import { useSelector } from "react-redux";
import DropDown from "../../../Components/UI/DropDown";
import moment from "moment";
import {
  getLeavesForMonthAndYear,
  createRequests,
} from "../../../services/employee.service";
import { IYearDropDown } from "../../../types/interfaces/IDateDropDown.interface";
import { generateYears } from "../../../utils/years";
import { getSectionRules } from '../../../services/employee.service';
import {
  setSnackBar,
  setToastMessage,
} from "../../../features/ToastSlice/toastSlice";

interface Preferences {
  workingHours: number;
  weekendsAllowedToWork: number;
  maxROThatCanBeApplied: number;
  maxROThatCanBeAppliedWeekends: number;
  overrideTimeOfRequests: number;
  consecutiveShifts: number;
  consecutiveDayShifts: number;
  consecutiveNightShifts: number;
  shiftDuration: number;
  setToAllocateConsecutiveShifts: boolean;
  patientForecastUtilization: number;
  shiftDistributionDayShifts: number;
  shiftDistributionNightShifts: number;
}
interface Props {
  yearDropDown: IYearDropDown;
  setYearDropDown: any;
  fetchAgain: boolean;
  setFetchAgain: (data: boolean) => void;
}
const HeaderFilter: React.FC<Props> = ({
  yearDropDown,
  setYearDropDown,
  fetchAgain,
  setFetchAgain,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const currentMonthIndex: number = moment().month() + 1;
  const handleClick = () => {
    setMonth(currentMonthIndex);
    setYear(moment().year());
    // fetchLeavesForMonthAndYear();
    setIsOpen(true);
  };
  const handleClose = () => {
    setIsOpen(false);
  };
  const currentYear: number = Number(moment().format("YYYY")) + 1;

  const years = generateYears(currentYear);

  const [selectedDaysCopy, setSelectedDaysCopy] = useState<any>();
  const [selectedDays, setSelectedDays] = useState<any>();
  const [datesToApply, setDatesToApply] = useState<[]>([] as any);
  const [month, setMonth] = useState<any>(currentMonthIndex);
  const [year, setYear] = useState<any>(moment().format("YYYY"));
  const [reason, setReason] = useState<string>();
  const [sectionRules,setSectionRules] = useState<Preferences>({} as Preferences);
  const fetchLeavesForMonthAndYear = async () => {
    const res: any = await getLeavesForMonthAndYear(month, year);
    if (res?.res) {
      setSelectedDays(res?.res?.leaves);
      setSelectedDaysCopy(res?.res?.leaves);
    }
  };

  const fetchSectionRules= async()=>{
   const res:any = await getSectionRules();
   setSectionRules(res?.res?.preferences as Preferences)
  }
  useEffect(() => {
    fetchLeavesForMonthAndYear();
    fetchSectionRules();
  }, [month]);
 
  const toggleDateSelection = (dayObj: any) => {
    const dateStr = moment(dayObj?.date).format("MM/DD/YYYY");
    const isDaySelected = selectedDays?.some((selectedDay: any) => {
      const selectedDateStr = moment(selectedDay?.date).format("MM/DD/YYYY");
      return selectedDateStr === dateStr;
    });
  
    const isDaySelectedList = selectedDaysCopy?.some((selectedDay: any) => {
      const selectedDateStr = moment(selectedDay?.date).format("MM/DD/YYYY");
      return selectedDateStr === dateStr;
    });
  
    const isWeekend = moment(dateStr).day() === 0 || moment(dateStr).day() === 6;
  
    if (isDaySelected && !isDaySelectedList) {
      const updatedDays = selectedDays.filter(
        (day: any) => moment(day.date).format("MM/DD/YYYY") !== dateStr
      );
      setSelectedDays(updatedDays);
      const sel = updatedDays.filter((date: any) => date._id === null);
      setDatesToApply(sel);
    } else if (!isDaySelected) {
      const canApply = moment(dateStr).isAfter(moment());
      if (canApply) {
        const selectedWeekendCount = selectedDays.reduce((count: number, day: any) => {
          return count + (moment(day.date).day() === 0 || moment(day.date).day() === 6 ? 1 : 0);
        }, 0);
  
        const selectedWeekdayCount = selectedDays.length - selectedWeekendCount;
  
        if (isWeekend && selectedWeekendCount < sectionRules.maxROThatCanBeAppliedWeekends) {
          const updatedDays: any = [...selectedDays, { ...dayObj }];
          setSelectedDays((prevSelectedDays: any) => {
            return [...prevSelectedDays, dayObj];
          });
          const sel = updatedDays.filter((date: any) => date._id === null);
          setDatesToApply(sel);
        } else if (!isWeekend && selectedWeekdayCount < sectionRules.maxROThatCanBeApplied) {
          const updatedDays: any = [...selectedDays, { ...dayObj }];
          setSelectedDays((prevSelectedDays: any) => {
            return [...prevSelectedDays, dayObj];
          });
          const sel = updatedDays.filter((date: any) => date._id === null);
          setDatesToApply(sel);
        } else {
          console.log("Select Other Dates");
          store.dispatch(setSnackBar("error"));
          store.dispatch(setToastMessage("You cannot apply more than the allowed ROs"));
        }
      }
    }
  };
  

  const resetDatesToApply = () => {
    setDatesToApply([]);
    setSelectedDays(selectedDaysCopy);
    setReason("");
  };

  const request = async () => {
    const transformedArray = datesToApply?.map((item: any) => {
      return {
        month: parseInt(item?.month), // Assuming month is always a number but stored as a string
        year: moment(item?.date).format("YYYY"),
        reason: reason,
        request: {
          type: "RO",
          timeOff: {
            date: item.date,
          },
        },
      };
    });

    const res:any = await createRequests(transformedArray);
    console.log(res);
    if(res?.err){
      store.dispatch(setSnackBar("error"));
      store.dispatch(setToastMessage(res?.res.error));
    }
    if (res?.res) {
      fetchLeavesForMonthAndYear();
      setFetchAgain(!fetchAgain);
      setReason("");
      setDatesToApply([]);
      setIsOpen(false);
      store.dispatch(setSnackBar("success"));
      store.dispatch(setToastMessage("RO applied successfully"));
      // setSelectedDays();
    }
  };
  return (
    <>
      <div className="flex flex-wrap -mx-[7px] items-center">
        <div className="flex-1">
          
        <div className="max-w-[180px]">
          <DropDown
            field={"name"}
            value={yearDropDown}
            options={years}
            setOptions={setYearDropDown}
          />
          </div>
          {/* <Search
            onChangeSearch={setSearch}
            onChangeOpen={setOpen}
            search={"search"}
            isOpen={open}
          >
            <Search.Input>
              <Search.Input.Icon />
              <Search.Input.Input
                placeholder="search"
                className="text-moon-14 text-trunks"
              />
            </Search.Input>
            <Search.Transition>
              <Search.Result>Search Results</Search.Result>
            </Search.Transition>
          </Search> */}
        </div>
        <div className="px-[7px]">
          <Button variant="tertiary" onClick={handleClick}>
            <ControlsPlus className="text-moon-24" />
            Apply Time-Off
          </Button>
        </div>
      </div>
      <TimeOffDrawer
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        handleClose={handleClose}
        month={month}
        year={year}
        setMonth={setMonth}
        setYear={setYear}
        selectedDays={selectedDays}
        toggleDateSelection={toggleDateSelection}
        datesToApply={datesToApply}
        setDatesToApply={resetDatesToApply}
        reason={reason}
        setReason={setReason}
        request={request}
        sectionRules={sectionRules}
      />
    </>
  );
};

export default HeaderFilter;
