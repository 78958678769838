import { Drawer, IconButton } from "@heathmont/moon-core-tw";
import { ControlsCloseSmall } from "@heathmont/moon-icons-tw";
import DetailShift from "./DetailShift";
import DateCard from "./DateCard";
import EmployeeCard from "./EmployeeCard";
import {
  IDayInfo,
  TeamMember,
} from "../../../../../../types/interfaces/IDayInfo";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../../store/store";
import { currentYear } from "../../../../../../features/Time Slice/timeSlice";
import TrackingFilter from "../../../../../../Components/Widgets/TrackingPool/TrackingFilter";

const listings = [
  {
    avatarImageUrl: "images/Avatar.png",
    chipText: "Leader",
    name: "Jacob Jones",
    spentHoursLeft: {
      current: 3,
      total: 5,
    },
    hoursLeft: {
      current: 48,
      total: 217,
    },
  },
  {
    avatarImageUrl: "images/Avatar.png",
    chipText: "Leader",
    name: "Jacob Jones",
    spentHoursLeft: {
      current: 3,
      total: 5,
    },
    hoursLeft: {
      current: 48,
      total: 217,
    },
  },
  {
    avatarImageUrl: "images/Avatar.png",
    chipText: "Chief Nurse",
    name: "Jacob Jones",
    spentHoursLeft: {
      current: 3,
      total: 5,
    },
    hoursLeft: {
      current: 48,
      total: 217,
    },
  },
  {
    avatarImageUrl: "images/Avatar.png",
    chipText: "Chief Nurse",
    name: "Jacob Jones",
    spentHoursLeft: {
      current: 3,
      total: 5,
    },
    hoursLeft: {
      current: 48,
      total: 217,
    },
  },
];

// const ShiftData = [
//   {
//     shift: "Day",
//     nursesTotal: 8,
//     nursesAvailable: 5,
//     chiefNurses: 2,
//     LeaderNurses: 1,
//   },
// ];
// interface SpecificDayRecord {
//   month: number;
//   monthName: string;
//   year: number;
//   scheduledBy: ScheduledBy;
//   dayNumber: number;
//   day: string;
//   dayShift: boolean;
//   nightShift: boolean;
//   status: string;
//   capacity: number;
//   utilization: string;
//   numberOfPatients: number;
//   criticalPatients: number;
//   employees: Employee[];
// }
// interface Employee {
//   employeeId: string;
//   employeeName: string;
//   designation: string;
//   shiftsSpent: number;
//   shiftsAllocated: number;
//   hoursSpent: number;
//   hoursAllocated: number;
// }
// interface ScheduledBy {
//   id: string;
//   firstName: string;
//   lastName: string;
// }

// interface ShiftData {
//   nurses: number;
//   seniorNurses: number;
//   hca: number;
//   staff: number;
//   shift: string;
// }
interface Props {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  data?: IDayInfo;
  handleClose?: () => void;
}
const ScheduleNurseDrawer: React.FC<Props> = ({
  isOpen,
  data,
  setIsOpen,
  handleClose,
}) => {
  return (
    <>
      <Drawer open={isOpen} setOpen={setIsOpen}>
        <Drawer.Panel className="max-w-[507px]">
          <div className="flex justify-between items-center p-3 border-b border-beerus">
            <h3 className="text-moon-20 font-bold transition-colors">
              View Schedule
            </h3>
            <IconButton variant="ghost" onClick={handleClose}>
              <ControlsCloseSmall />
            </IconButton>
          </div>
          <div className="p-6">
            <DateCard
              avatarImageUrl={""}
              avatarSize="xs"
              scheduledBy={
                data?.scheduledBy.firstName + " " + data?.scheduledBy?.lastName
              }
              day={data?.monthName}
              date={data?.dayNumber}
              month={data?.monthName}
              year={data?.year}
            />
            <hr className="border-t border-beerus my-[24px]" />

            <DetailShift shiftData={data as IDayInfo} />

            <hr className="border-t border-beerus mt-[24px] mb-[12px]" />
            <div className="bg-goku p-[8px] rounded-[8px] flex items-center">
              <h3 className="text-moon-14 font-semibold">Team Members</h3>
            </div>
            <hr className="border-t border-beerus my-[12px]" />
            <TrackingFilter
              specificDayEmployees={data?.teamMembers as TeamMember[]}
            />
            {/* <div
              className="emloyeecrd mt-[20px]"
              style={{ height: "calc(100dvh - 430px)" }}
            >
              {data?.teamMembers?.map((listing: TeamMember, index) => (
                <EmployeeCard key={index} teamMember={listing} />
              ))}
            </div> */}
          </div>
        </Drawer.Panel>
        <Drawer.Backdrop />
      </Drawer>
    </>
  );
};

export default ScheduleNurseDrawer;
