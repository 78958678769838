import { api } from "../utils/axios";

export const getSwapRequest = async () => {
  try {
    const { data, ok } = await api.get(`/swapRequest?page=1&limit=100`);
    if (ok) {
      return { res: data, err: null };
    }
  } catch (error) {
    return { res: null, err: error };
  }
};

export const createSwapRequest = async (params: any) => {
  try {
    const { data, ok } = await api.post(`/swapRequest`, params);
    if (ok) {
      return { res: data, err: null };
    } else {
      return { res: null, err: data };
    }
  } catch (error) {
    return { res: null, err: error };
  }
};
