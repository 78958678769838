import React, { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
  Navigate,
} from "react-router-dom";

import NurseScheduler from "./pages/scheduler/month";
import ScheduleHeader from "./Components/layouts/header/ScheduleHeader";
import NurseSchedulerWeekly from "./pages/scheduler/week";
import Account from "./pages/account";
import Swap from "./pages/swap";
import TimeOff from "./pages/time-off";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "./store/store";
import spinnerSvc from "./utils/spinner-service";
import { setAuthUser } from "./features/Auth Slice/authSlice";
import LoaderSpinner from "./Components/Loader/Loader";
import EmailSent from "./Components/email-sent";
import Forgot from "./pages/Forgot";
import NewPassword from "./Components/new-password";
import LogIn from "./Components/Login";
import Toast from "./Components/Toast";
import RoutesWrapper from "./RoutesWrapper";

function App() {
  const [loading, setLoading] = useState(null);
  const roles = ["nurse", "senior nurse", "HCA"];
  const dispatch: any = useDispatch();
  const data = useSelector((state: RootState) => state.auth.User);
  const { snackbar, toastMessage } = useSelector(
    (state: RootState) => state.toast
  );
  useEffect(() => {
    const subscription = spinnerSvc.requestInProgress.subscribe((data: any) => {
      setLoading(data);
    });
    return () => {
      subscription.unsubscribe();
    };
  }, []);

  const user = JSON.parse(sessionStorage.getItem("user")!);
  useEffect(() => {
    console.log(process.env.REACT_APP_API_URL);
    if (user) dispatch(setAuthUser(user));
  }, []);

  return (
    <>
      {loading && <LoaderSpinner />}
      <Toast
        snackbar={snackbar}
        toastMessage={toastMessage}
        // setSnackbar={setSnackbar}
        // setToastMessage={setToastMessage}
      />
      {roles?.includes(user?.designation) ? (
        <Router>
          <ScheduleHeader />
          <RoutesWrapper>
            <Routes>
              <Route path="/" element={<NurseScheduler />} />
              {/* <Route path="/nurse-weekly" element={<NurseSchedulerWeekly />} /> */}
              <Route path="/time-off" element={<TimeOff />} />
              <Route path="/swap" element={<Swap />} />
              <Route path="/profile" element={<Account />} />{" "}
            </Routes>
          </RoutesWrapper>
        </Router>
      ) : (
        <Router>
          <Routes>
            <Route path="/" element={<LogIn />} />
            <Route path="/forgot-password" element={<Forgot />} />
            <Route path="/email-sent" element={<EmailSent />} />
            <Route path="/new-password" element={<NewPassword />} />
            <Route path="*" element={<Navigate to="/" />} />
          </Routes>
        </Router>
      )}
    </>
  );
}

export default App;
