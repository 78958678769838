import { Button, Drawer, IconButton } from "@heathmont/moon-core-tw";
import {
  ControlsCloseSmall,
  GenericAlarmRound,
} from "@heathmont/moon-icons-tw";
import FilterShift from "./FilterShift";
import CalendarSwap from "./CalendarSwap";
import DropDownUser from "../../../Components/UI/DropDownUser";
import { ISwapRequestResponse, ShiftDayDetails } from "..";
import { useState } from "react";
import {
  createSwapRequest,
  getSwapRequest,
} from "../../../services/request.service";
import { setRequests } from "../../../features/Request/requestSlice";
import { useDispatch } from "react-redux";
import { setToastMessage } from "../../../features/ToastSlice/toastSlice";
interface SwapsProps {
  shifts: ShiftDayDetails[];
  isOpen: boolean;
  setIsOpen: (value: boolean) => void;
}

const SwapDrawer = ({ isOpen, setIsOpen, shifts }: SwapsProps) => {
  const dispatch = useDispatch();
  const [selectedButton, setSelectedButton] = useState<"day" | "night">("day");
  const [error, setError] = useState("");
  const [selectedDate, setSelectedDate] = useState<{
    month: number;
    day: number;
    year: number;
    shift: "day" | "night";
  } | null>(null);

  const handleResetClick = () => {
    setSelectedDate(null);
  };

  const handleDiscard = () => {
    setSelectedDate(null);
    setIsOpen(false);
  };

  const handleApply = async () => {
    if (!selectedDate) return;
    const response: any = await createSwapRequest({
      day: selectedDate.day,
      month: selectedDate.month,
      year: selectedDate.year,
      shift: selectedDate.shift,
    });
    if (response?.res) {
      fetchSwapRequests();
      handleDiscard();
    } else {
      setError(response.err.message || "");
    }
  };

  const fetchSwapRequests = async () => {
    const res: any = await getSwapRequest();
    if (res?.res) {
      dispatch(setRequests(res.res.data as ISwapRequestResponse[]));
    }
  };

  return (
    <Drawer open={isOpen} setOpen={setIsOpen}>
      <Drawer.Panel className="max-w-[391px] flex flex-col">
        <div className="flex justify-between items-center p-3 border-b border-beerus">
          <h3 className="text-moon-20 font-bold transition-colors">
            Apply Swap Request
          </h3>
          <IconButton variant="ghost" onClick={() => setIsOpen(false)}>
            <ControlsCloseSmall />
          </IconButton>
        </div>
        <div className="p-6 flex-1 overflow-y-auto">
          <div className="my-[15px]">
            <CalendarSwap
              selectedDate={selectedDate}
              setSelectedDate={setSelectedDate}
              shifts={shifts}
              shift={selectedButton}
            />
          </div>
          <div className="space-y-2">
            <div className="flex items-center">
              <span className="w-4 h-4 mr-3 bg-[#ffca4f] rounded-full"></span>
              <span className="text-gray-700">Day Shift</span>
            </div>
            <div className="flex items-center">
              <span className="w-4 h-4 mr-3 bg-[#272d36] rounded-full"></span>
              <span className="text-gray-700">Night Shift</span>
            </div>
          </div>
        </div>

        {error && (
          <p className="px-[24px] text-moon-14 text-red-600 flex-1">{error}</p>
        )}
        <div className="px-[24px]">
          <div className="flex items-start space-x-2">
            <i className="text-moon-18 leading-1">
              <GenericAlarmRound />
            </i>
            <p className="text-moon-14 text-gray-600 flex-1">
              <span className="font-semibold"> Note: </span>Once you will submit
              the swap request, it will not be undone.
            </p>
          </div>
        </div>
        <hr className="border-t border-beerus mt-[24px]" />
        <div className="p-6 flex items-center space-x-3">
          <div>
            <Button
              onClick={handleResetClick}
              variant="ghost"
              disabled={!!!selectedDate}
            >
              Reset
            </Button>
          </div>
          <div className="flex items-center flex-1 justify-end  space-x-3">
            <Button onClick={handleDiscard} variant="secondary">
              Discard
            </Button>
            <Button onClick={handleApply} className="bg-hit">
              Apply
            </Button>
          </div>
        </div>
      </Drawer.Panel>
      <Drawer.Backdrop />
    </Drawer>
  );
};

export default SwapDrawer;
