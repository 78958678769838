import { Avatar, Button, Drawer, IconButton } from "@heathmont/moon-core-tw";
import {
  ArrowsTransfer,
  ControlsCloseSmall,
  GenericAlarmRound,
} from "@heathmont/moon-icons-tw";
import React, { useState } from "react";
import DetailShift from "./DetailShift";
import DropDown from "../../../../../../Components/UI/DropDown";

const data = [{ name: "Suzan Roy" }];
const ShiftData = [
  {
    shift: "Day",
    nursesTotal: 8,
    nursesAvailable: 5,
    chiefNurses: 2,
    LeaderNurses: 1,
  },
];
const DateCard = ({
  avatarImageUrl,
  avatarSize,
  scheduledBy,
  day,
  date,
  month,
  year,
}: any) => {
  const [isOpen, setIsOpen] = useState(false);
  const handleClick = () => setIsOpen(true);
  const handleClose = () => setIsOpen(false);

  return (
    <>
      {" "}
      <div className="flex items-center">
        <div className="text-center px-[14px] py-[7px] bg-slate-100 rounded-lg">
          <h3 className="text-moon-18 font-semibold">{day}</h3>
          <div className="text-moon-32  font-semibold leading-none">{date}</div>
        </div>
        <div className="flex-1 px-[22px]">
          <h3 className="text-moon-18 font-semibold mb-[8px]">
            {month} {year}
          </h3>
          <div className="flex">
            <span className="text-gray-400">Scheduled by </span>
            <div className="flex flex-1 ml-[7px]">
              <div className="flex flex-wrap items-center justify-around gap-2 w-full">
                <Avatar
                  imageUrl={avatarImageUrl}
                  size={avatarSize}
                  className="rounded-full overflow-hidden"
                />
                <span className="flex-1 font-bold">{scheduledBy}</span>
              </div>
            </div>
          </div>
        </div>
        <Button variant="secondary" onClick={handleClick}>
          <i className="text-moon-18">
            <ArrowsTransfer />
          </i>{" "}
          Swap
        </Button>
      </div>
      <Drawer open={isOpen} setOpen={setIsOpen}>
        <Drawer.Panel className="max-w-[391px] flex flex-col">
          <div className="flex justify-between items-center p-3 border-b border-beerus">
            <h3 className="text-moon-20 font-bold transition-colors">
              Apply Swap
            </h3>
            <IconButton variant="ghost" onClick={handleClose}>
              <ControlsCloseSmall />
            </IconButton>
          </div>
          <div className="p-6  flex-1">
            {" "}
            <div className="flex items-center">
              <div className="text-center px-[14px] py-[7px] bg-slate-100 rounded-lg">
                <h3 className="text-moon-18 font-semibold">{day}</h3>
                <div className="text-moon-32  font-semibold leading-none">
                  {date}
                </div>
              </div>
              <div className="flex-1 px-[22px]">
                <h3 className="text-moon-18 font-semibold mb-[8px]">
                  {month} {year}
                </h3>
                <div className="flex">
                  <span className="text-gray-400">Scheduled by </span>
                  <div className="flex flex-1 ml-[7px]">
                    <div className="flex flex-wrap items-center justify-around gap-2 w-full">
                      <Avatar
                        imageUrl={avatarImageUrl}
                        size={avatarSize}
                        className="rounded-full overflow-hidden"
                      />
                      <span className="flex-1 font-bold">{scheduledBy}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <hr className="border-t border-beerus my-[24px]" />
            {/* {ShiftData.map((shiftData, index) => (
              <DetailShift key={index} shiftData={shiftData} />
            ))} */}
            <h4 className="text-moon-14 font-semibold mb-2">Swap with</h4>
            {/* <DropDown option="Choose Employee" data={data} /> */}
          </div>{" "}
          <div className="px-[24px]">
            <div className="flex items-start space-x-2">
              <i className="text-moon-18 leading-1">
                <GenericAlarmRound />
              </i>
              <p className="text-moon-14 text-gray-600 flex-1">
                <span className="font-semibold"> Note:</span> Once you will
                submit the time-off request, it will be undone.
              </p>
            </div>
          </div>
          <hr className="border-t border-beerus mt-[24px]" />
          <div className="p-6 flex items-center space-x-3">
            <div>
              <Button variant="ghost" disabled>
                Reset
              </Button>
            </div>
            <div className="flex items-center flex-1 justify-end  space-x-3">
              <Button variant="secondary">Discard</Button>
              <Button className="bg-frieza">Apply</Button>
            </div>
          </div>
        </Drawer.Panel>
        <Drawer.Backdrop />
      </Drawer>
    </>
  );
};

export default DateCard;
