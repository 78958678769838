import { Button, Drawer, IconButton, Input } from "@heathmont/moon-core-tw";
import {
  ControlsCloseSmall,
  GenericAlarmRound,
} from "@heathmont/moon-icons-tw";
import React, { useEffect, useState } from "react";
import InlineCalendar from "../../../Components/UI/InlineCalendar";
import { useSelector } from "react-redux";
import { RootState } from "../../../store/store";

const TimeOffDrawer = (props: any) => {
  const { date } = useSelector((state: RootState) => state.time);
  const [selectedDays, setSelectedDays] = useState<any>([]);

  const handleDaySelect = (day: any) => {
    if (selectedDays.includes(day)) {
      setSelectedDays(selectedDays.filter((d: any) => d !== day));
    } else {
      setSelectedDays([...selectedDays, day]);
    }
  };

  return (
    <Drawer open={props.isOpen} setOpen={props.setIsOpen}>
      <Drawer.Panel className="max-w-[391px] flex flex-col">
        <div className="flex justify-between items-center p-3 border-b border-beerus">
          <h3 className="text-moon-20 font-bold transition-colors">
            Apply Time-off
          </h3>
          <IconButton variant="ghost" onClick={props.handleClose}>
            <ControlsCloseSmall />
          </IconButton>
        </div>
        <div className="p-6 flex-1 overflow-y-auto">
          <h4 className="text-blackcst text-moon-14">Date & Time (From/To)</h4>
          <p className="text-gray-600 text-moon-14 mb-[15px]">
            Select the time off days you want to apply for
          </p>{" "}
          <InlineCalendar
            selectedDays={props.selectedDays}
            month={props.month}
            year={props.year}
            setYear={props.setYear}
            setMonth={props.setMonth}
            dateSelected={props.toggleDateSelection}
            clearAppliedDays={props.setDatesToApply}
          />
          <h4 className="text-gray-600 my-[16px]">
            You have selected{" "}
            <h4 className="text-gray-600 my-[16px]">
              You can apply for up to  &nbsp;
              <span className="font-semibold text-blackcst">
                {props.sectionRules.maxROThatCanBeApplied} &nbsp;
              </span>
              days off on weekdays and  &nbsp;
              <span className="font-semibold text-blackcst">
                {props.sectionRules.maxROThatCanBeAppliedWeekends} &nbsp;
              </span>
              days off on weekends.
            </h4>
          </h4>
          <Input
            type="text"
            placeholder="Reason"
            id="test-2"
            name="reason"
            autoComplete="off"
            value={props.reason}
            onChange={(e: any) => props.setReason(e.target.value)}
          />
        </div>
        <div className="px-[24px]">
          <div className="flex items-start space-x-2">
            <i className="text-moon-18 leading-1">
              <GenericAlarmRound />
            </i>
            <p className="text-moon-14 text-gray-600 flex-1">
              <span className="font-semibold"> Note:</span>Once you will submit
              the time-off request, it will be undone.
            </p>
          </div>
        </div>
        <hr className="border-t border-beerus mt-[24px]" />
        <div className="p-6 flex items-center space-x-3">
          <div>
            <Button
              variant="ghost"
              disabled={props?.datesToApply?.length === 0}
              onClick={props?.setDatesToApply}
            >
              Reset
            </Button>
          </div>
          <div className="flex items-center flex-1 justify-end  space-x-3">
            <Button
              variant="secondary"
              disabled={props?.datesToApply?.length === 0}
              onClick={() => {
                props?.setDatesToApply();
                props.handleClose();
              }}
            >
              Discard
            </Button>
            <Button
              className="bg-frieza"
              disabled={props?.datesToApply?.length === 0 ? true : false}
              onClick={props?.request}
            >
              Apply
            </Button>
          </div>
        </div>
      </Drawer.Panel>
      <Drawer.Backdrop />
    </Drawer>
  );
};

export default TimeOffDrawer;
