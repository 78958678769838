import React, { useEffect, useState } from "react";
import { ArrowsRightShort, OtherMoon } from "@heathmont/moon-icons-tw";
import { Icon } from "@iconify/react";
import GroupAvatar from "../../UI/GroupAvatar";
import { Button } from "@heathmont/moon-core-tw";
import moment from "moment";
import { IUserData } from "../../../types/interfaces/IUserData.interface";
import { RootState, store } from "../../../store/store";
import { useSelector } from "react-redux";
import ScheduleNurseDrawer from "../../../pages/scheduler/month/widgets/drawer/schedule-drawer";
import { ISpecificDayRecord } from "../../../types/interfaces/ISpecificDayRecord";
import { getDayInfo } from "../../../services/schedule.service";
import { IDayInfo } from "../../../types/interfaces/IDayInfo";
import {
  setSnackBar,
  setToastMessage,
} from "../../../features/ToastSlice/toastSlice";
import OverlappedAvatar from "../../UI/OverlappedAvatar";
const avatars = [
  {
    image: "./images/Avatar.png",
    border: "border-rose-500",
  },
  {
    image: "./images/Avatar.png",
    border: "border-indigo-600",
  },
  {
    image: "./images/Avatar.png",
    border: "border-blue-500",
  },
  {
    image: "./images/Avatar.png",
    border: "border-krillin",
  },
];
const momentDate = moment();
const currentDate = momentDate?.date();
const currentMonthNumber = momentDate?.month() + 1;
const currentYear = momentDate?.year();

const NurseSchedule = () => {
  const overflowCount = 12;
  const user = useSelector((state: RootState) => state.auth.User);
  const date = moment().format("DD MMMM YYYY");
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [specificDayRecord, setSpecificDayRecord] = useState<IDayInfo>();
  // const [currentTime, setCurrentTime] = useState(new Date());
  // const [isDay, setIsDay] = useState<boolean>(false);

  useEffect(() => {
    fetchDayInfo();
    // const interval = setInterval(() => {
    //   getGreetingAndImage(new Date());
    // }, 1000); // Update time every second

    // return () => clearInterval(interval); // Cleanup the interval on unmount
  }, []);
  const fetchDayInfo = async () => {
    const res = await getDayInfo(currentDate, currentMonthNumber, currentYear);
    // console.log("res of specific day: ", res);
    if (res?.res) {
      setSpecificDayRecord(res?.res as IDayInfo);
    }
  };
  // const getGreetingAndImage = (time: any) => {
  //   const currentHour = time.getHours();

  //   if (currentHour >= 6 && currentHour < 18) {
  //     // Morning
  //     setIsDay(true);
  //   }
  //   // Night
  //   else setIsDay(false);
  // };
  const handleClose = () => {
    setIsOpen(!isOpen);
  };

  const handleViewClick = () => {
    setIsOpen(true);
    // fetchDayInfo();
  };
  return (
    <>
      <div className="flex bg-[rgba(39,45,54,0.15)] rounded-md overflow-hidden 2xl:px-4 px-2 py-5">
        <div className="flex-1">
          <ul className="text-moon-14 flex items-center flex-wrap gap-[8px]">
            {/* <li className="text-trunks">{user?.hospitalName}</li>
          <li className="text-trunks 2xl:text-moon-24 text-moon-18">
            <ArrowsRightShort />
          </li> */}
            <li className="text-white">{user?.branchName}</li>
            <li className="text-white 2xl:text-moon-24 text-moon-18">
              <ArrowsRightShort />
            </li>
            <li className="text-white">{user?.department}</li>
          </ul>
          <div className="flex items-center justify-between">
            <h3 className="2xl:2xl:text-moon-24 text-moon-16 font-semibold text-white">{date}</h3>
            {specificDayRecord?.shift === "Day Shift" ? (
              <div className="inline-flex mt-4 text-krillin gap-[8px]">
                <i className="2xl:text-moon-24 text-moon-18">
                  {" "}
                  <Icon icon="uil:sun" />
                </i>
                <span className="text-moon-14">Day</span>
              </div>
            ) : specificDayRecord?.shift === "Night Shift" ? (
              <div className="inline-flex mt-4 gap-[8px]">
                <i className="2xl:text-moon-24 text-moon-18">
                  {" "}
                  <OtherMoon />
                </i>
                <span className="text-moon-14">Night</span>
              </div>
            ) : (
              <span className="font-bold text-xs text-white line-clamp-1">Not Aligned</span>
            )}
          </div>
        </div>
        <div className="text-right text-white flex flex-col justify-between mt-[-8px]">
          {/* <GroupAvatar avatars={avatars} overflowCount={overflowCount} /> */}
          {/* <OverlappedAvatar specificDayRecord={specificDayRecord as IDayInfo} /> */}
          {specificDayRecord?.shift !== "Not Aligned" && (
            <Button
              variant="ghost"
              className="ml-auto"
              onClick={() => handleViewClick()}
            >
              View
            </Button>
          )}
        </div>
      </div>
      <ScheduleNurseDrawer
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        handleClose={handleClose}
        data={specificDayRecord}
      />
    </>
  );
};

export default NurseSchedule;
