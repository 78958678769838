import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ISwapRequestResponse } from "../../pages/swap";
import { RootState } from "../../store/store";

interface RequestState {
  requests: ISwapRequestResponse[];
}

const initialState: RequestState = {
  requests: [],
};

const requestSlice = createSlice({
  name: "swapRequest",
  initialState,
  reducers: {
    setRequests: (
      state: RequestState,
      action: PayloadAction<ISwapRequestResponse[]>
    ) => {
      state.requests = action.payload;
    },
  },
});

export const { setRequests } = requestSlice.actions;
export const requestsSelector = (state: RootState) => state.request.requests;
export default requestSlice.reducer;
