import { Button, Search } from "@heathmont/moon-core-tw";
import React, { useState } from "react";
import { ControlsPlus } from "@heathmont/moon-icons-tw";
import SwapDrawer from "./SwapDrawer";
import { ShiftDayDetails } from "..";

interface HeaderProps {
  shifts: ShiftDayDetails[];
}

const HeaderFilter = ({ shifts }: HeaderProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const handleClick = () => setIsOpen(true);

  return (
    <>
      <div className="flex flex-wrap -mx-[7px] items-center">
        <div className="w-2/5 px-[7px]"></div>
        <div className="w-3/5 px-[7px]">
          <div className="flex flex-wrap gap-[14px]">
            <div className="flex-1">
              {/* <DropDown option="Month" data={month} /> */}
            </div>
            <div className="flex-1">
              {/* <DropDown option="Year" data={year} /> */}
            </div>
            <Button variant="tertiary" onClick={handleClick}>
              <ControlsPlus className="text-moon-24" />
              Apply Swap
            </Button>
          </div>
        </div>
      </div>
      <SwapDrawer isOpen={isOpen} setIsOpen={setIsOpen} shifts={shifts} />
    </>
  );
};

export default HeaderFilter;
